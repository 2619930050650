// src/components/Sidebar.js

import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  FaUsers, 
  FaHandHoldingHeart, 
  FaBoxOpen, 
  FaTruckLoading, 
  FaFileInvoiceDollar, 
  FaClipboardCheck, 
  FaChartBar,
  FaChartLine,
  FaQuestionCircle,
} from 'react-icons/fa';
import { MdAddBox } from 'react-icons/md';
import '../styles/Sidebar.css';
import { authService } from '../services/authService';
import { userService } from '../services/userService';

const logoUrl = 'https://firebasestorage.googleapis.com/v0/b/donatingsimplified.appspot.com/o/Donating%20Simplified%20White%20Wordmark.png?alt=media&token=234482ff-4a28-42ed-adce-ca04a42fb120';

function Sidebar() {
  const [userRole, setUserRole] = useState('User');
  const location = useLocation();

  useEffect(() => {
    const fetchUserRole = async () => {
      const currentUser = await authService.getCurrentUser();
      if (currentUser) {
        const role = await userService.getUserRole(currentUser.uid);
        setUserRole(role);
      }
    };
    fetchUserRole();
  }, []);

  const navItems = [
    { path: '/my-impact-dashboard', icon: <FaChartBar />, label: 'My Impact Dashboard', roles: ['SystemAdmin', 'Admin', 'User'] },
    { path: '/option-analytics', icon: <FaChartLine />, label: 'Option Analytics', roles: ['SystemAdmin'] },
    { path: '/create-new-charity', icon: <FaHandHoldingHeart />, label: 'Create New Charity', roles: ['SystemAdmin'] },
    { path: '/start-new-donation', icon: <MdAddBox />, label: 'New Donation', roles: ['Admin', 'User'] },
    { path: '/manage-donations', icon: <FaBoxOpen />, label: 'Manage Donations', roles: ['SystemAdmin'] },
    { path: '/manage-logistics', icon: <FaTruckLoading />, label: 'Manage Logistics', roles: ['SystemAdmin'] },
    { path: '/quotes', icon: <FaFileInvoiceDollar />, label: 'My Quotes', roles: ['Admin', 'User'] },
    { path: '/manage-pickups', icon: <FaClipboardCheck />, label: 'Manage Pickups', roles: ['Admin', 'User'] },
    { path: '/my-team', icon: <FaUsers />, label: 'My Team', roles: ['SystemAdmin', 'Admin', 'User'] },
    { path: '/help', icon: <FaQuestionCircle />, label: 'Help', roles: ['SystemAdmin', 'Admin', 'User'] },
    
  ];

  const filteredNavItems = navItems.filter(item => item.roles.includes(userRole));

  return (
    <nav className="sidebar">
      <div className="sidebar-header">
        <Link to="/dashboard" className="logo-link">
          {logoUrl && <img src={logoUrl} alt="Logo" className="logo" />}
        </Link>
      </div>
      <ul className="nav-links">
        {filteredNavItems.map((item) => (
          <li key={item.path} className={location.pathname === item.path ? 'active' : ''}>
            <Link to={item.path}>
              {item.icon}
              <span className="link-text">{item.label}</span>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Sidebar;