// src/services/completedDonationsService.js

import { db } from '../firebase/config';
import { collection, doc, setDoc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { quoteService } from './quoteService';
import { logisticsService } from './logisticsService';
import { donationService } from './donationService';

const COLLECTION_NAME = 'completedDonations';

class CompletedDonationError extends Error {
  constructor(message, code) {
    super(message);
    this.name = 'CompletedDonationError';
    this.code = code;
  }
}

export const completedDonationsService = {
  async createCompletedDonation(quoteId, taxReceiptUrls, quotePdfUrl, palletGroups, distributionCenterId, fmvAssessmentUrl, description, costBasis) {
    try {
      console.log('Starting completed donation creation for quote:', quoteId);

      // Validate required parameters
      if (!quoteId) throw new CompletedDonationError('Quote ID is required', 'MISSING_QUOTE_ID');
      if (!distributionCenterId) throw new CompletedDonationError('Distribution Center ID is required', 'MISSING_DC_ID');
      if (!Array.isArray(palletGroups)) throw new CompletedDonationError('Pallet groups must be an array', 'INVALID_PALLET_GROUPS');

      // Fetch quote and validate
      const quote = await quoteService.getById(quoteId);
      if (!quote) {
        throw new CompletedDonationError('Quote not found', 'QUOTE_NOT_FOUND');
      }
      console.log('Quote found:', quote.id);

      // Fetch donation and item details
      const donation = quote.donationId ? await donationService.getById(quote.donationId) : null;
      console.log('Donation found:', donation?.id);

      // Find donation item if it exists
      const donationItem = donation?.items?.find(item => 
        item.id === quote.itemId || 
        item.itemID === quote.itemId || 
        item.itemId === quote.itemId
      );
      console.log('Donation item found:', !!donationItem);

      // Determine valid description with proper fallbacks
      const validDescription = this.validateAndCleanDescription(
        description,
        quote.description,
        donationItem?.description
      );
      console.log('Using description:', validDescription);

      // Fetch all logistics tasks for this quote
      const logisticsTasks = await logisticsService.getTasks({ quoteId });
      console.log(`Found ${logisticsTasks.length} logistics tasks`);

      // Calculate total pallet count from pallet groups
      const totalPalletCount = palletGroups.reduce((sum, group) => 
        sum + (Number(group.palletQuantity) || 0), 0
      );

      // Prepare the completed donation data
      const completedDonationData = {
        id: doc(collection(db, COLLECTION_NAME)).id,
        quoteId: quote.id,
        companyId: quote.companyId,
        donationId: quote.donationId,
        itemId: quote.itemId,
        description: validDescription,
        quantity: this.validateNumberField(quote.quantity, 'quantity'),
        palletCount: this.validateNumberField(totalPalletCount, 'palletCount'),
        fairMarketValue: this.validateNumberField(quote.fairMarketValue, 'fairMarketValue'),
        costBasis: this.validateNumberField(costBasis, 'costBasis'),
        completionDate: quote.completionDate || new Date().toISOString(),
        taxReceiptUrls: this.validateTaxReceiptUrls(taxReceiptUrls),
        quotePdfUrl: quotePdfUrl || null,
        logisticsTaskIds: logisticsTasks.map(task => task.id),
        palletGroups: this.validatePalletGroups(palletGroups),
        distributionCenterId,
        fmvAssessmentUrl: fmvAssessmentUrl || null,
        status: 'completed',
        createdAt: new Date().toISOString(),
        lastUpdatedAt: new Date().toISOString()
      };

      // Validate the final data object
      this.validateCompletedDonationData(completedDonationData);

      // Create the document in Firestore
      const docRef = doc(collection(db, COLLECTION_NAME), completedDonationData.id);
      await setDoc(docRef, completedDonationData);
      
      console.log('Successfully created completed donation:', completedDonationData.id);
      return completedDonationData;

    } catch (error) {
      console.error('Error creating completed donation:', error);
      throw new CompletedDonationError(
        `Failed to create completed donation: ${error.message}`,
        error.code || 'CREATE_ERROR'
      );
    }
  },

  validateAndCleanDescription(providedDesc, quoteDesc, donationItemDesc) {
    const cleanText = (text) => typeof text === 'string' ? text.trim() : '';
    
    const validDescription = cleanText(providedDesc) || 
                           cleanText(quoteDesc) || 
                           cleanText(donationItemDesc) || 
                           'No description available';
    
    if (validDescription.length > 500) {
      return validDescription.substring(0, 500);
    }
    
    return validDescription;
  },

  validateNumberField(value, fieldName) {
    const num = Number(value);
    if (isNaN(num)) {
      console.warn(`Invalid ${fieldName} value:`, value);
      return 0;
    }
    return num;
  },

  validateTaxReceiptUrls(urls) {
    if (!urls || typeof urls !== 'object') {
      return {};
    }
    
    // Clean the URLs object - ensure all values are strings
    const cleanedUrls = {};
    Object.entries(urls).forEach(([key, value]) => {
      if (typeof value === 'string' && value.trim()) {
        cleanedUrls[key] = value.trim();
      }
    });
    
    return cleanedUrls;
  },

  validatePalletGroups(groups) {
    if (!Array.isArray(groups)) return [];
    
    return groups.map(group => ({
      charityId: group.charityId || null,
      palletGroup: this.validateNumberField(group.palletGroup, 'palletGroup'),
      palletQuantity: this.validateNumberField(group.palletQuantity, 'palletQuantity'),
      taxReceiptUrl: typeof group.taxReceiptUrl === 'string' ? group.taxReceiptUrl.trim() : null
    })).filter(group => group.charityId && group.palletQuantity > 0);
  },

  validateCompletedDonationData(data) {
    const requiredFields = [
      'id', 'quoteId', 'companyId', 'description', 'distributionCenterId',
      'palletGroups', 'createdAt'
    ];

    requiredFields.forEach(field => {
      if (!data[field]) {
        throw new CompletedDonationError(
          `Missing required field: ${field}`,
          'VALIDATION_ERROR'
        );
      }
    });

    if (data.palletGroups.length === 0) {
      throw new CompletedDonationError(
        'Completed donation must have at least one valid pallet group',
        'VALIDATION_ERROR'
      );
    }
  },

  async getById(completedDonationId) {
    try {
      if (!completedDonationId) {
        throw new CompletedDonationError('Completed donation ID is required', 'INVALID_PARAMETER');
      }

      const donationDoc = await getDoc(doc(db, COLLECTION_NAME, completedDonationId));
      if (!donationDoc.exists()) {
        console.log(`No completed donation found with ID: ${completedDonationId}`);
        return null;
      }

      return { id: donationDoc.id, ...donationDoc.data() };
    } catch (error) {
      console.error('Error getting completed donation by ID:', error);
      throw new CompletedDonationError(
        `Failed to get completed donation: ${error.message}`,
        'GET_ERROR'
      );
    }
  },

  async getAll() {
    try {
      console.log('Fetching all completed donations');
      
      const querySnapshot = await getDocs(collection(db, COLLECTION_NAME));
      const donations = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      
      console.log(`Retrieved ${donations.length} completed donations`);
      return donations;
    } catch (error) {
      console.error('Error getting all completed donations:', error);
      throw new CompletedDonationError(
        `Failed to get all completed donations: ${error.message}`,
        'GET_ALL_ERROR'
      );
    }
  },

  async getByDistributionCenter(distributionCenterId) {
    try {
      if (!distributionCenterId) {
        throw new CompletedDonationError('Distribution center ID is required', 'INVALID_PARAMETER');
      }

      console.log(`Fetching completed donations for distribution center: ${distributionCenterId}`);
      
      const q = query(
        collection(db, COLLECTION_NAME), 
        where("distributionCenterId", "==", distributionCenterId)
      );
      
      const querySnapshot = await getDocs(q);
      const donations = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      
      console.log(`Found ${donations.length} completed donations for distribution center ${distributionCenterId}`);
      return donations;
    } catch (error) {
      console.error(`Error getting completed donations for distribution center ${distributionCenterId}:`, error);
      throw new CompletedDonationError(
        `Failed to get completed donations for distribution center: ${error.message}`,
        'QUERY_ERROR'
      );
    }
  },

  async getByCompany(companyId) {
    try {
      if (!companyId) {
        throw new CompletedDonationError('Company ID is required', 'INVALID_PARAMETER');
      }

      console.log(`Fetching completed donations for company: ${companyId}`);
      
      const q = query(
        collection(db, COLLECTION_NAME), 
        where("companyId", "==", companyId)
      );
      
      const querySnapshot = await getDocs(q);
      const donations = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      
      console.log(`Found ${donations.length} completed donations for company ${companyId}`);
      return donations;
    } catch (error) {
      console.error(`Error getting completed donations for company ${companyId}:`, error);
      throw new CompletedDonationError(
        `Failed to get completed donations for company: ${error.message}`,
        'QUERY_ERROR'
      );
    }
  },

  async getByDateRange(startDate, endDate) {
    try {
      if (!startDate || !endDate) {
        throw new CompletedDonationError('Both start date and end date are required', 'INVALID_PARAMETER');
      }

      // Ensure dates are in ISO string format
      const start = new Date(startDate).toISOString();
      const end = new Date(endDate).toISOString();

      console.log(`Fetching completed donations between ${start} and ${end}`);
      
      const q = query(
        collection(db, COLLECTION_NAME), 
        where("completionDate", ">=", start),
        where("completionDate", "<=", end)
      );
      
      const querySnapshot = await getDocs(q);
      const donations = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      
      console.log(`Found ${donations.length} completed donations in date range`);
      return donations;
    } catch (error) {
      console.error(`Error getting completed donations for date range ${startDate} to ${endDate}:`, error);
      throw new CompletedDonationError(
        `Failed to get completed donations for date range: ${error.message}`,
        'QUERY_ERROR'
      );
    }
  },

  async getWithDetails() {
    try {
      console.log('Fetching all completed donations with details');
      
      const completedDonations = await this.getAll();
      console.log(`Found ${completedDonations.length} completed donations, fetching details...`);

      const detailedDonations = await Promise.all(completedDonations.map(async (donation) => {
        try {
          const [quote, logisticsTasks] = await Promise.all([
            quoteService.getById(donation.quoteId),
            Promise.all(donation.logisticsTaskIds.map(taskId => logisticsService.getTaskById(taskId)))
          ]);

          return {
            ...donation,
            quote: quote || null,
            logisticsTasks: logisticsTasks.filter(task => task !== null) // Filter out any null tasks
          };
        } catch (error) {
          console.warn(`Error fetching details for donation ${donation.id}:`, error);
          // Return the donation without details rather than failing the entire request
          return {
            ...donation,
            quote: null,
            logisticsTasks: [],
            error: error.message
          };
        }
      }));

      console.log('Successfully fetched all donation details');
      return detailedDonations;
    } catch (error) {
      console.error('Error getting completed donations with details:', error);
      throw new CompletedDonationError(
        `Failed to get completed donations with details: ${error.message}`,
        'QUERY_ERROR'
      );
    }
  },

  async getByCharityId(charityId) {
    try {
      if (!charityId) {
        throw new CompletedDonationError('Charity ID is required', 'INVALID_PARAMETER');
      }

      console.log(`Fetching completed donations for charity: ${charityId}`);
      
      const querySnapshot = await getDocs(collection(db, COLLECTION_NAME));
      
      const donations = querySnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(donation => 
          donation.palletGroups?.some(group => group.charityId === charityId)
        );
      
      console.log(`Found ${donations.length} completed donations for charity ${charityId}`);
      return donations;
    } catch (error) {
      console.error(`Error getting completed donations for charity ${charityId}:`, error);
      throw new CompletedDonationError(
        `Failed to get completed donations for charity: ${error.message}`,
        'QUERY_ERROR'
      );
    }
  },

  async getByStatus(status) {
    try {
      if (!status) {
        throw new CompletedDonationError('Status is required', 'INVALID_PARAMETER');
      }

      console.log(`Fetching completed donations with status: ${status}`);
      
      const q = query(
        collection(db, COLLECTION_NAME), 
        where("status", "==", status)
      );
      
      const querySnapshot = await getDocs(q);
      const donations = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      
      console.log(`Found ${donations.length} completed donations with status ${status}`);
      return donations;
    } catch (error) {console.error(`Error getting completed donations for status ${status}:`, error);
    throw new CompletedDonationError(
      `Failed to get completed donations by status: ${error.message}`,
      'QUERY_ERROR'
    );
  }
},

async getByQuoteId(quoteId) {
  try {
    if (!quoteId) {
      throw new CompletedDonationError('Quote ID is required', 'INVALID_PARAMETER');
    }

    console.log(`Fetching completed donation for quote: ${quoteId}`);
    
    const q = query(
      collection(db, COLLECTION_NAME), 
      where("quoteId", "==", quoteId)
    );
    
    const querySnapshot = await getDocs(q);
    const donations = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    
    // Should only be one completed donation per quote
    return donations[0] || null;
  } catch (error) {
    console.error(`Error getting completed donation for quote ${quoteId}:`, error);
    throw new CompletedDonationError(
      `Failed to get completed donation by quote: ${error.message}`,
      'QUERY_ERROR'
    );
  }
},

async getByDonationId(donationId) {
  try {
    if (!donationId) {
      throw new CompletedDonationError('Donation ID is required', 'INVALID_PARAMETER');
    }

    console.log(`Fetching completed donations for donation: ${donationId}`);
    
    const q = query(
      collection(db, COLLECTION_NAME), 
      where("donationId", "==", donationId)
    );
    
    const querySnapshot = await getDocs(q);
    const donations = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    
    console.log(`Found ${donations.length} completed donations for donation ${donationId}`);
    return donations;
  } catch (error) {
    console.error(`Error getting completed donations for donation ${donationId}:`, error);
    throw new CompletedDonationError(
      `Failed to get completed donations by donation: ${error.message}`,
      'QUERY_ERROR'
    );
  }
},

async getByPalletCount(minCount, maxCount) {
  try {
    if (typeof minCount !== 'number' || typeof maxCount !== 'number') {
      throw new CompletedDonationError('Min and max pallet counts must be numbers', 'INVALID_PARAMETER');
    }

    console.log(`Fetching completed donations with pallet count between ${minCount} and ${maxCount}`);
    
    const querySnapshot = await getDocs(collection(db, COLLECTION_NAME));
    
    const donations = querySnapshot.docs
      .map(doc => ({ id: doc.id, ...doc.data() }))
      .filter(donation => 
        donation.palletCount >= minCount && donation.palletCount <= maxCount
      );
    
    console.log(`Found ${donations.length} completed donations within pallet count range`);
    return donations;
  } catch (error) {
    console.error(`Error getting completed donations by pallet count range:`, error);
    throw new CompletedDonationError(
      `Failed to get completed donations by pallet count: ${error.message}`,
      'QUERY_ERROR'
    );
  }
},

async getByValueRange(minValue, maxValue) {
  try {
    if (typeof minValue !== 'number' || typeof maxValue !== 'number') {
      throw new CompletedDonationError('Min and max values must be numbers', 'INVALID_PARAMETER');
    }

    console.log(`Fetching completed donations with fair market value between ${minValue} and ${maxValue}`);
    
    const querySnapshot = await getDocs(collection(db, COLLECTION_NAME));
    
    const donations = querySnapshot.docs
      .map(doc => ({ id: doc.id, ...doc.data() }))
      .filter(donation => 
        donation.fairMarketValue >= minValue && donation.fairMarketValue <= maxValue
      );
    
    console.log(`Found ${donations.length} completed donations within value range`);
    return donations;
  } catch (error) {
    console.error(`Error getting completed donations by value range:`, error);
    throw new CompletedDonationError(
      `Failed to get completed donations by value range: ${error.message}`,
      'QUERY_ERROR'
    );
  }
},

async getRecentDonations(limit = 10) {
  try {
    if (typeof limit !== 'number' || limit <= 0) {
      throw new CompletedDonationError('Limit must be a positive number', 'INVALID_PARAMETER');
    }

    console.log(`Fetching ${limit} most recent completed donations`);
    
    const querySnapshot = await getDocs(collection(db, COLLECTION_NAME));
    
    const donations = querySnapshot.docs
      .map(doc => ({ id: doc.id, ...doc.data() }))
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      .slice(0, limit);
    
    console.log(`Retrieved ${donations.length} recent donations`);
    return donations;
  } catch (error) {
    console.error('Error getting recent completed donations:', error);
    throw new CompletedDonationError(
      `Failed to get recent completed donations: ${error.message}`,
      'QUERY_ERROR'
    );
  }
}
};

export default completedDonationsService;