// src/services/quoteService.js

import { db, storage } from '../firebase/config';
import { collection, doc, setDoc, getDoc, getDocs, updateDoc, query, where } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { companyService } from './companyService';
import { donationService } from './donationService';
import { format } from 'date-fns';
import { userService } from './userService';
import { distributionCenterService } from './distributionCenterService';

const COLLECTION_NAME = 'quotes';

class QuoteError extends Error {
  constructor(message, code) {
    super(message);
    this.name = 'QuoteError';
    this.code = code;
  }
}

export const quoteService = {
  async createQuote(quoteData) {
    try {
      console.log('Creating quote with data:', quoteData);
  
      const newQuoteRef = doc(collection(db, COLLECTION_NAME));
      const quoteWithId = {
        ...quoteData,
        id: newQuoteRef.id,
        status: 'pending',
        createdAt: new Date().toISOString(),
        validityPeriod: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString() // 30 days validity
      };
  
      let pdfUrl = null;
      try {
        const pdfBlob = await this.generatePDF(quoteWithId);
        console.log('PDF generated successfully');
        pdfUrl = await this.uploadPDF(pdfBlob, newQuoteRef.id);
        console.log('PDF uploaded successfully, URL:', pdfUrl);
      } catch (pdfError) {
        console.error('Failed to generate or upload PDF:', pdfError);
      }
  
      if (pdfUrl) {
        quoteWithId.pdfUrl = pdfUrl;
      }
  
      await setDoc(newQuoteRef, quoteWithId);
      console.log('Quote saved to Firestore');
  
      return quoteWithId;
    } catch (error) {
      console.error('Error creating quote:', error);
      throw new QuoteError(`Failed to create quote: ${error.message}`, 'CREATE_ERROR');
    }
  },

  async getById(quoteId) {
    try {
      const quoteDoc = await getDoc(doc(db, COLLECTION_NAME, quoteId));
      return quoteDoc.exists() ? { id: quoteDoc.id, ...quoteDoc.data() } : null;
    } catch (error) {
      console.error('Error getting quote by ID:', error);
      throw new QuoteError(`Failed to get quote: ${error.message}`, 'GET_ERROR');
    }
  },

  async update(quoteId, updateData) {
    try {
      const quoteRef = doc(db, COLLECTION_NAME, quoteId);
      const timestamp = new Date().toISOString();
      
      await updateDoc(quoteRef, {
        ...updateData,
        updatedAt: timestamp
      });
      
      return await this.getById(quoteId);
    } catch (error) {
      console.error('Error updating quote:', error);
      throw new QuoteError(`Failed to update quote: ${error.message}`, 'UPDATE_ERROR');
    }
  },

  async fetchAllQuotes(distributionCenterId) {
    try {
      const q = query(
        collection(db, COLLECTION_NAME),
        where("distributionCenterId", "==", distributionCenterId)
      );
      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error('Error fetching quotes:', error);
      throw new QuoteError(`Failed to fetch quotes: ${error.message}`, 'FETCH_ERROR');
    }
  },

  async approveQuote(quoteId, approvalData) {
    try {
      const quote = await this.getById(quoteId);
      if (!quote) {
        throw new QuoteError('Quote not found', 'NOT_FOUND');
      }

      const updatedPdfBlob = await this.generatePDF(quote, approvalData.signature);
      const pdfUrl = await this.uploadPDF(updatedPdfBlob, quoteId);

      // Update donation item status
      if (quote.donationId && quote.itemId) {
        try {
          await donationService.updateItem(quote.donationId, quote.itemId, {
            status: 'Quote Approved'
          });
        } catch (error) {
          console.error('Error updating donation item status:', error);
        }
      }

      const updatedQuote = await this.update(quoteId, {
        status: 'approved',
        signature: approvalData.signature,
        approverId: approvalData.approverId,
        approvalTimestamp: approvalData.approvalTimestamp,
        pdfUrl: pdfUrl
      });

      return updatedQuote;
    } catch (error) {
      console.error('Error approving quote:', error);
      throw new QuoteError(`Failed to approve quote: ${error.message}`, 'APPROVAL_ERROR');
    }
  },

  async rejectQuote(quoteId, rejectionData) {
    try {
      const quote = await this.getById(quoteId);
      if (!quote) {
        throw new QuoteError('Quote not found', 'NOT_FOUND');
      }

      // Update donation item status
      if (quote.donationId && quote.itemId) {
        try {
          await donationService.updateItem(quote.donationId, quote.itemId, {
            status: 'Quote Rejected'
          });
        } catch (error) {
          console.error('Error updating donation item status:', error);
        }
      }

      const updatedQuote = await this.update(quoteId, {
        status: 'rejected',
        rejectionReason: rejectionData.reason,
        rejectedBy: rejectionData.userId,
        rejectionTimestamp: new Date().toISOString()
      });

      return updatedQuote;
    } catch (error) {
      console.error('Error rejecting quote:', error);
      throw new QuoteError(`Failed to reject quote: ${error.message}`, 'REJECTION_ERROR');
    }
  },

  async generatePDF(quote, signature = null) {
    const pdf = new jsPDF();
    
    try {
      // Fetch additional data
      const company = await companyService.getById(quote.companyId);
      const dc = await distributionCenterService.getById(quote.distributionCenterId);
      const requester = await userService.getById(quote.requestorId);
      const donation = quote.donationId ? await donationService.getById(quote.donationId) : null;
      const donationItem = donation?.items?.find(item => item.itemId === quote.itemId);
      
      // PDF Styling Constants
      const primaryColor = [41, 128, 185];  // #2980b9
      const secondaryColor = [44, 62, 80];  // #2c3e50
      const accentColor = [52, 152, 219];   // #3498db
      const textColor = [51, 51, 51];       // #333333
      const lightGray = [245, 247, 250];    // #f5f7fa
      
      const margin = 20;
      const pageWidth = pdf.internal.pageSize.width;
      const contentWidth = pageWidth - (2 * margin);
      
      // Helper function for consistent text styling
      const setTextStyle = (size, style = 'normal', color = textColor) => {
        pdf.setFontSize(size);
        pdf.setFont('helvetica', style);
        pdf.setTextColor(...color);
      };

      // Helper function to format address
      const formatAddress = (address) => {
        if (!address) return ['No address available'];
        if (typeof address === 'string') return [address];
        
        const addressParts = [];
        if (address.street) addressParts.push(address.street);
        if (address.city || address.state || address.zip) {
          const cityLine = [address.city, address.state, address.zip]
            .filter(Boolean)
            .join(', ');
          if (cityLine) addressParts.push(cityLine);
        }
        return addressParts.length ? addressParts : ['No address available'];
      };

      // Background header
      pdf.setFillColor(...lightGray);
      pdf.rect(0, 0, pageWidth, 60, 'F');
      
      // Company Logo and Header
      if (company?.logo) {
        try {
          const logoImage = await this.getImageFromUrl(company.logo);
          if (logoImage) {
            pdf.addImage(logoImage, 'PNG', margin, margin, 40, 20);
          }
        } catch (logoError) {
          console.warn('Failed to load company logo:', logoError);
        }
      }

      // Quote Title and Reference
      setTextStyle(24, 'bold', primaryColor);
      pdf.text('SHIPPING QUOTE', pageWidth - margin, 35, { align: 'right' });
      
      setTextStyle(10, 'normal', secondaryColor);
      pdf.text(`Reference: ${quote.id}`, pageWidth - margin, 45, { align: 'right' });
      
      // Company & Distribution Center Information
      let yPos = 80;
      
      // From section
      setTextStyle(12, 'bold', primaryColor);
      pdf.text('FROM:', margin, yPos);
      
      setTextStyle(10, 'normal');
      yPos += 10;
      const companyAddressLines = [
        company?.name || 'Company Name',
        ...formatAddress(company?.address),
        company?.phone || '',
        company?.email || ''
      ].filter(Boolean);
      
      pdf.text(companyAddressLines, margin, yPos);

      // Distribution Center section
      setTextStyle(12, 'bold', primaryColor);
      pdf.text('DISTRIBUTION CENTER:', pageWidth/2, yPos - 10);
      
      setTextStyle(10, 'normal');
      const dcAddressLines = [
        dc?.name || 'DC Name',
        ...formatAddress(dc?.address),
        dc?.phone || '',
        dc?.email || ''
      ].filter(Boolean);
      
      pdf.text(dcAddressLines, pageWidth/2, yPos);

      // Quote Details Section
      yPos += Math.max(companyAddressLines.length, dcAddressLines.length) * 7 + 20;
      
      pdf.setFillColor(...lightGray);
      pdf.rect(margin, yPos - 10, contentWidth, 40, 'F');
      
      setTextStyle(12, 'bold', primaryColor);
      pdf.text('QUOTE DETAILS', margin + 5, yPos);
      
      setTextStyle(10, 'normal');
      const detailsLeft = [
        `Date: ${format(new Date(quote.createdAt), 'MMMM dd, yyyy')}`,
        `Valid Until: ${format(new Date(quote.validityPeriod), 'MMMM dd, yyyy')}`,
        `Requester: ${requester?.name || 'N/A'}`
      ];
      
      const detailsRight = [
        `Quote Status: ${quote.status.toUpperCase()}`,
        `Total Pallets: ${donationItem?.palletCount || 'N/A'}`,
        `Total Quantity: ${donationItem?.quantity || 'N/A'} ${donationItem?.unitOfMeasure || ''}`
      ];
      
      pdf.text(detailsLeft, margin + 5, yPos + 15);
      pdf.text(detailsRight, pageWidth/2, yPos + 15);

      // Shipping Details Table
      yPos += 50;
      
      pdf.autoTable({
        startY: yPos,
        head: [['Description', 'Pallets', 'Quantity', 'Price Per Pallet', 'Total Price']],
        body: [[
          donationItem?.description || 'N/A',
          donationItem?.palletCount?.toString() || 'N/A',
          `${donationItem?.quantity || 'N/A'} ${donationItem?.unitOfMeasure || ''}`,
          formatCurrency(quote.pricePerPallet || 0),
          formatCurrency(quote.amount || 0)
        ]],
        styles: {
          fontSize: 10,
          cellPadding: 8
        },
        headStyles: {
          fillColor: [...primaryColor],
          textColor: '#FFFFFF',
          fontStyle: 'bold'
        },
        columnStyles: {
          0: { cellWidth: 'auto' },
          1: { cellWidth: 30, halign: 'center' },
          2: { cellWidth: 40, halign: 'center' },
          3: { cellWidth: 40, halign: 'right' },
          4: { cellWidth: 40, halign: 'right' }
        },
        alternateRowStyles: {
          fillColor: [...lightGray]
        }
      });

      // Terms and Conditions
      yPos = pdf.autoTable.previous.finalY + 20;
      
      setTextStyle(12, 'bold', primaryColor);
      pdf.text('TERMS AND CONDITIONS', margin, yPos);
      
      setTextStyle(9, 'normal');
      const terms = quote.terms || 'Standard shipping terms and conditions apply.';
      const wrappedTerms = pdf.splitTextToSize(terms, contentWidth);
      pdf.text(wrappedTerms, margin, yPos + 10);

      // Additional Notes
      if (quote.notes) {
        yPos += 30 + (wrappedTerms.length * 5);
        setTextStyle(12, 'bold', primaryColor);
        pdf.text('ADDITIONAL NOTES', margin, yPos);
        
        setTextStyle(9, 'normal');
        const wrappedNotes = pdf.splitTextToSize(quote.notes, contentWidth);
        pdf.text(wrappedNotes, margin, yPos + 10);
      }

      // Signature Section
      if (signature || quote.status === 'approved') {
        const signatureY = pdf.internal.pageSize.height - 60;
        
        setTextStyle(12, 'bold', primaryColor);
        pdf.text('AUTHORIZATION', margin, signatureY - 10);
        
        if (signature) {
          pdf.addImage(signature, 'PNG', margin, signatureY, 50, 30);
        }
        
        setTextStyle(8, 'normal', secondaryColor);
        pdf.text('Authorized Signature', margin, signatureY + 35);
        
        if (quote.approvalTimestamp) {
          pdf.text(
            `Approved on: ${format(new Date(quote.approvalTimestamp), 'MMMM dd, yyyy')}`,
            margin + 80, signatureY + 35
          );
        }
      }

      // Footer
      const footerY = pdf.internal.pageSize.height - 15;
      setTextStyle(8, 'normal', secondaryColor);
      pdf.text(`Generated on ${format(new Date(), 'MMMM dd, yyyy HH:mm')}`, margin, footerY);
      pdf.text('Page 1 of 1', pageWidth - margin, footerY, { align: 'right' });

      return pdf.output('blob');
    } catch (error) {
      console.error('Error generating PDF:', error);
      throw new QuoteError(`Failed to generate PDF: ${error.message}`, 'PDF_ERROR');
    }
  },

  async uploadPDF(pdfBlob, quoteId) {
    try {
      const storageReference = ref(storage, `${COLLECTION_NAME}/${quoteId}.pdf`);
      await uploadBytes(storageReference, pdfBlob);
      return await getDownloadURL(storageReference);
    } catch (error) {
      console.error('Error uploading PDF:', error);
      throw new QuoteError(`Failed to upload PDF: ${error.message}`, 'UPLOAD_ERROR');
    }
  },

  async getImageFromUrl(url) {
    return new Promise((resolve) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = () => resolve(img);
      img.onerror = () => {
        console.warn(`Failed to load image from URL: ${url}`);
        resolve(null);
      };
      img.src = url;
    });
  },

  async getApprovedQuotes() {
    try {
      const q = query(collection(db, COLLECTION_NAME), where("status", "==", "approved"));
      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error('Error getting approved quotes:', error);
      throw new QuoteError(`Failed to get approved quotes: ${error.message}`, 'FETCH_ERROR');
    }
  },

  async getRejectedQuotes() {
    try {
      const q = query(collection(db, COLLECTION_NAME), where("status", "==", "rejected"));
      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error('Error getting rejected quotes:', error);
      throw new QuoteError(`Failed to get rejected quotes: ${error.message}`, 'FETCH_ERROR');
    }
  },

  async getQuotesByDonationId(donationId) {
    try {
      const q = query(collection(db, COLLECTION_NAME), where("donationId", "==", donationId));
      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error('Error getting quotes by donation ID:', error);
      throw new QuoteError(`Failed to get quotes: ${error.message}`, 'FETCH_ERROR');
    }
  },

  async getQuotesByItemId(itemId) {
    try {
      const q = query(collection(db, COLLECTION_NAME), where("itemId", "==", itemId));
      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error('Error getting quotes by item ID:', error);
      throw new QuoteError(`Failed to get quotes: ${error.message}`, 'FETCH_ERROR');
    }
  },

  async completeQuote(quoteId) {
    try {
      const quote = await this.getById(quoteId);
      if (!quote) {
        throw new QuoteError('Quote not found', 'NOT_FOUND');
      }

      if (quote.status !== 'approved') {
        throw new QuoteError('Only approved quotes can be marked as completed', 'INVALID_STATUS');
      }

      const updatedQuote = await this.update(quoteId, {
        status: 'completed',
        completedAt: new Date().toISOString()
      });

      // Update donation item status if needed
      if (quote.donationId && quote.itemId) {
        try {
          await donationService.updateItem(quote.donationId, quote.itemId, {
            status: 'Quote Completed'
          });
        } catch (error) {
          console.error('Error updating donation item status:', error);
        }
      }

      return updatedQuote;
    } catch (error) {
      console.error('Error completing quote:', error);
      throw new QuoteError(`Failed to complete quote: ${error.message}`, 'COMPLETION_ERROR');
    }
  },

  async archiveQuote(quoteId) {
    try {
      const quote = await this.getById(quoteId);
      if (!quote) {
        throw new QuoteError('Quote not found', 'NOT_FOUND');
      }

      await this.update(quoteId, {
        archived: true,
        archivedAt: new Date().toISOString()
      });

      return true;
    } catch (error) {
      console.error('Error archiving quote:', error);
      throw new QuoteError(`Failed to archive quote: ${error.message}`, 'ARCHIVE_ERROR');
    }
  },

  async bulkUpdateQuotes(quoteIds, updateData) {
    try {
      const updates = quoteIds.map(quoteId => 
        this.update(quoteId, updateData)
      );
      
      await Promise.all(updates);
      return true;
    } catch (error) {
      console.error('Error performing bulk update:', error);
      throw new QuoteError(`Failed to perform bulk update: ${error.message}`, 'BULK_UPDATE_ERROR');
    }
  },

  async duplicateQuote(quoteId) {
    try {
      const originalQuote = await this.getById(quoteId);
      if (!originalQuote) {
        throw new QuoteError('Quote not found', 'NOT_FOUND');
      }

      // Remove specific fields that shouldn't be duplicated
      const {
        id,
        status,
        signature,
        approvalTimestamp,
        approverId,
        rejectionReason,
        rejectedBy,
        rejectionTimestamp,
        createdAt,
        updatedAt,
        pdfUrl,
        ...quoteToDuplicate
      } = originalQuote;

      // Create new quote with duplicated data
      const duplicatedQuote = await this.createQuote({
        ...quoteToDuplicate,
        notes: `Duplicated from Quote ${originalQuote.id}\n${quoteToDuplicate.notes || ''}`.trim()
      });

      return duplicatedQuote;
    } catch (error) {
      console.error('Error duplicating quote:', error);
      throw new QuoteError(`Failed to duplicate quote: ${error.message}`, 'DUPLICATION_ERROR');
    }
  }
};

function formatCurrency(amount) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
}

export default quoteService;